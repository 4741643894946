// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesSy from '@src/assets/data/locales/sy.json'
import userMessagesRu from '@src/assets/data/locales/ru.json'
import userMessagesIr from '@src/assets/data/locales/ir.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'
import userMessagesKg from '@src/assets/data/locales/kg.json'
import userMessagesTj from '@src/assets/data/locales/tj.json'
import userMessagesUr from '@src/assets/data/locales/ur.json'
import { useRTL } from '../hooks/useRTL'

const menuMessages = {
    en: { ...userMessagesEn },
    sy: { ...userMessagesSy },
    ru: { ...userMessagesRu },
    ir: { ...userMessagesIr },
    pt: { ...userMessagesPt },
    kg: { ...userMessagesKg },
    tj: { ...userMessagesTj },
    ur: { ...userMessagesUr }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
    const [_, setIsRtl] = useRTL()
    const getDefaultLanguage = window.localStorage.getItem('language')
        ? window.localStorage.getItem('language')
        : 'en'

    // ** States
    const [locale, setLocale] = useState(getDefaultLanguage)
    const [messages, setMessages] = useState(menuMessages[getDefaultLanguage])

    // ** Switches Language
    const switchLanguage = (lang) => {
        setLocale(lang)
        window.localStorage.setItem('language', lang)
        setMessages(menuMessages[lang])
    }

    useEffect(() => {
        setIsRtl(
            getDefaultLanguage === 'sy' ||
                getDefaultLanguage === 'ir' ||
                getDefaultLanguage === 'ur'
        )
    }, [getDefaultLanguage])

    return (
        <Context.Provider value={{ locale, switchLanguage }}>
            <IntlProvider
                key={locale}
                locale={locale}
                messages={messages}
                onError={() => null}
                defaultLocale={getDefaultLanguage}
            >
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export { IntlProviderWrapper, Context as IntlContext }
